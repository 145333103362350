import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Divider } from '@opengov/component-library/capital';
import { FigmaEmbed, PatternExample, UsageBlock } from '../../../components';
import LayoutComponents from '../../../components/capital/Layouts/LayoutComponents';
export const _frontmatter = {
  "title": "Divider",
  "componentId": "divider",
  "description": "Dividers are used to create visual separation between objects.",
  "activeTab": "components"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = LayoutComponents;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p><strong parentName="p">{`Documentation in progress------------------------`}</strong></p>
    <h2><strong parentName="h2">{`Variants`}</strong></h2>
    <PatternExample example={<Divider.Horizontal />} title="Horizontal" mdxType="PatternExample" />
    <PatternExample example={<Divider.Thin />} title="Thin" mdxType="PatternExample" />
    <PatternExample example={<Divider.ListItem />} title="List Item" mdxType="PatternExample" />
    <PatternExample example={<Divider.Vertical />} title="Vertical" mdxType="PatternExample" />
    <h2><strong parentName="h2">{`Usage`}</strong></h2>
    <UsageBlock mdxType="UsageBlock">
  <UsageBlock.Correct>
    <p>...</p>
  </UsageBlock.Correct>
  <UsageBlock.Incorrect>
    <p>...</p>
  </UsageBlock.Incorrect>
    </UsageBlock>
    <h2>{`Layout`}</h2>
    <PatternExample example={"..."} title="..." description={"Description about layout structure"} mdxType="PatternExample" />
    <h2>{`Content`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      